import { lazy } from 'react';
import { Outlet, useRoutes } from 'react-router';

const MainPage = lazy(
  () => import('../pages/MainPage') /* webpackChunkName: "main-page" */
);
const Editor = lazy(
  () => import('../pages/Editor') /* webpackChunkName: "editor" */
);
const Manual = lazy(
  () => import('../pages/Manual') /* webpackChunkName: "Manual" */
);
const About = lazy(
  () => import('../pages/About') /* webpackChunkName: "about" */
);
const Privacy = lazy(
  () => import('../pages/Privacy') /* webpackChunkName: "privacy" */
);
const Terms = lazy(
  () => import('../pages/Terms') /* webpackChunkName: "terms" */
);

export function useAppRoutes() {
  return useRoutes([
    {
      element: <Outlet />,
      children: [
        {
          path: '/',
          children: [
            {
              index: true,
              element: <MainPage />,
            },
          ],
        },
        {
          path: '/editor',
          children: [
            {
              index: true,
              element: <Editor />,
            },
          ],
        },
        {
          path: '/manual',
          children: [
            {
              index: true,
              element: <Manual />,
            },
          ],
        },
        {
          path: '/about',
          children: [
            {
              index: true,
              element: <About />,
            },
          ],
        },
        {
          path: '/privacy',
          children: [
            {
              index: true,
              element: <Privacy />,
            },
          ],
        },
        {
          path: '/terms',
          children: [
            {
              index: true,
              element: <Terms />,
            },
          ],
        },
      ],
    },
  ]);
}
